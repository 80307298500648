<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="org">
      <GroupHeader :group="org" />

      <v-card-title>{{$t('events.welcome.join-org')}}</v-card-title>
      <v-card-text v-if="status && status.verification_required">
        <v-alert type="info">
          {{$t('events.welcome.verification-code-org-message')}}
        </v-alert>

        <p>
          {{$t('events.welcome.verification-code-description')}}
        </p>

      </v-card-text>
      <v-alert v-if="!$store.getters.isLoading && result && result.status == 'ERROR'" type="error" class="mx-4">
        <p>{{ result.msg }}</p>
        <p>{{$t('events.welcome.verification-code-mismatch')}}</p>
      </v-alert>

      <v-form v-if="status" ref="form" v-model="valid" @submit.prevent="join" lazy-validation class="mx-4 mb-4">
          <v-text-field 
              v-if="status.verification_required"
              v-model="code" 
              :rules="codeRules" 
              :label="$t('events.welcome.verification-code-label')" 
              :required="status.verification_required">
          </v-text-field>

          <v-checkbox 
            v-if="status.consent_required"
            v-model="consented"
            :rules="consentRules"
            >
            <template v-slot:label>
            <VueMarkdown class="markdown-content">{{event.join_consent_msg}}</VueMarkdown>
            </template>
          </v-checkbox>

          <div v-if="status.gender_required">
            <VueMarkdown class="markdown-content">{{event.join_gender_msg}}</VueMarkdown>
            <v-radio-group v-model="gender" :rules="genderRules" row class="mt-0" >
              <v-radio :label="$t('shared.gender-male')" value="M"></v-radio>
              <v-radio :label="$t('shared.gender-female')" value="F"></v-radio>
            </v-radio-group>
          </div>

          <v-btn color="primary" :loading="$store.getters.isLoading" @click="join">{{$t('events.welcome.verify-code')}}</v-btn>
      </v-form>
      <br/>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import groupService from "@/services/groupService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import SodispRules from '@/components/SodispRules.vue';
import GroupHeader from '@/components/GroupHeader.vue';
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();
import { getAuth } from "firebase/auth";

export default {
  name: "OrgJoin",
  components: {
    VueMarkdown,
    SodispRules,
    GroupHeader,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      org: null,
      code: null,
      gender: null,
      consented: null,
      teamNames: null,
      status: null,
      profile: null,
      result: null,
      valid: false,
    };
  },

  async mounted() {
    await this.loadData(this.$route.params.id);

    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      // not logged in, force back into the register flow
      this.$router.push({ name: 'register', query: { org: this.org.id, verificationCode: this.$route.query.verificationCode } });
    }
  },

  methods: {
      
    async getGroupStatus() {
      if (this.user) {
        var response = await profileService.getGroupStatus(this.org.id);
        this.status = response.data;
        this.profile = (await profileService.get(/*full:*/false)).data;

        this.code = this.$route.query.verificationCode;
        if (this.isConnected) {
          // not logged in, force back into the register flow
          this.$router.push({ name: 'organizationWelcome', query: { id: this.org.id } });
        }
      }
    },

    async join() {
      if (this.$refs.form.validate()) {
        console.log('Trying to join with code', this.code);
        this.result = (await profileService.joinGroup(this.org.id, {
          code: this.code, 
          consented: this.consented, 
          gender: this.gender,
        })).data;
        if (this.result && this.result.status == "OK") {
          this.$router.replace({ name: "organizationWelcome", params: { group: this.group.id} });
          return;
        }
        await this.getGroupStatus();
      }
    },
    
    async loadData(id) {
      var response = await groupService.get(id);
      this.org = response.data;
      await this.getGroupStatus();
      if (this.code) {
        await this.join();
      }
      var meta = {
        title: this.org.name + ' on ' + tenant.name,
        description: this.org.description,
        image: this.org.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    

  },
  computed: {
    isConnected() {
      return this.status && this.status.connected;
    },
    breadcrumbItems() {
      return !this.org ? [] : [
        { text: this.org.name, exact: true, to: { name: 'orgorg', params: {id: this.org.id}} },
        { text: this.$t('events.welcome.join'), disabled: true },
      ];
    },
    consentRules() {
      return !this.event || !this.status || !this.status.consent_required ? [] : [
        v => !!v || this.$t('events.welcome.consent-validation-msg'),
      ];
    },
    codeRules() {
      return !this.event || !this.status || !this.status.verification_required ? [] : [
        v => !!v || this.$t('events.welcome.verification-code-validation-msg'),
      ];
    },
    genderRules() {
      return !this.event || !this.status || !this.status.gender_required ? [] : [
        v => !!v || this.$t('events.welcome.gender-validation-msg'),
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadData(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .markdown-content {
    p { margin-bottom: 0 !important;}
  }
</style>

